.sessionsWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    padding: 2rem 10rem;
}
.contentContainer {
    flex-grow: 2;
}

.sessionListContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.sessionItemWrapper {
    margin: 1rem;
    padding:  1rem 2rem;
    color: white;
    font-size: 1.6rem;
    background-color: var(--secondary);
    border-radius: .5rem;
    transition: .3s;
}
.sessionItemWrapper:hover {
    background-color: var(--primary);
}

.itemQuestionCount {
    margin: .5rem 0;
    font-size: 1.2rem;
    font-weight: 600;
}

.questionCount {
    font-weight: 200;
}

.active {
    background-color: var(--primary);
    opacity: .5;
}