
.questionAnsweredBadge {
    text-align: center;
    padding: 0 20px;
    color: green;
   font-weight:100;
   font-size: 1rem;
   border: 1px solid green;
   border-radius: 10px;
   background-color: rgba(13, 167, 13, 0.3);
   display: flex;
   align-items: center;
   justify-content: space-around;
min-width: 17.5rem;
}

.questionSelectedBadge {
    text-align: center;
    padding: 0 20px;
    color: #492b01;
   font-weight:600;
   font-size: 1rem;
   border: 1px solid #774703;
   border-radius: 10px;
   background-color: #FF9806;
   display: flex;
   align-items: center;
   justify-content: space-around;
   min-width: 17.5rem;
}

.questionSelectedBadge h3 ,
.questionAnsweredBadge h3 {
    margin: 0;
    padding: 1rem 2rem;
    font-weight:300;
    font-size: 1.6rem;
}
