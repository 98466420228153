.itemWrapper {
    margin: 1rem 2rem;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, .8);
    border-radius: .5rem;
}

.questionContent {
    padding: 1rem 2rem .5rem 2rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-self: start;
    align-items: flex-start;
}

.question {
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
    font-size: 2.2rem;
    font-weight: 600;
    color: black;
    width: 60%;
    flex-grow: 2;
}
.info {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    
}

.actions {
    display: flex;
    justify-content: flex-end;
    width: 15%;
}